<template>
  <div class="wap-recharge" :class="pageType === 'user' ? 'user' : ''">
    <headerBar
      :title="$t('ti-xian-0')"
      @back="back"
      :right-text="$t('ti-xian-ji-lu')"
      @detail="toDetail"
    ></headerBar>
    <div class="recharge-form">
     
      <div class="form-item">
        <div class="label">{{ $t('xuan-ze-bi-zhong') }}</div>
        <div class="input">
          <Field v-model="form.bitName" readonly @click="changeBit"></Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('qu-kuai-lian-wang-luo') }}</div>
        <div class="link-list">
          <div
            class="link"
            v-for="(item, i) in linkList"
            :key="i"
            :class="form.link == item.key ? 'active' : ''"
            @click="changeLink(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('di-zhi-0') }}</div>
        <div class="input">
          <Field
            v-model="form.address"
            :placeholder="$t('qing-shu-ru-di-zhi-0')"
          ></Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('jin-e') }}</div>
        <div class="input">
          <Field v-model="form.number" :placeholder="$t('qing-shu-ru-jine')">
            <template #button>
              <span class="link" @click="setAll">{{ $t('quan-bu') }}</span>
            </template>
          </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('zi-jin-mi-ma') }}</div>
        <div class="input">
          <Field
            v-model="form.password"
            type="password"
            :placeholder="$t('qing-shu-ru-di-zhi-0')"
          ></Field>
        </div>
      </div>

      <div class="form-item form-desc">
        <div class="green">
          {{ $t('dang-qian-yu-e') }}
          ${{ userMoney }}
        </div>
        <div class="flex-center-between">
          <div class="gray">
            {{ $t('shi-ji-dao-zhang') }}
            ${{ form.number || 0.0 }}
          </div>
          <div class="link">
            {{ $t('shou-xu-fei-0') }}
            0.00%
          </div>
        </div>
      </div>

      <div class="form-item">
        <Button class="submit-btn" @click="submit">{{ $t('ti-jiao') }}</Button>
      </div>
    </div>

    <Popup position="bottom" v-model="showTypePopup">
      <Picker
        :columns="typeList"
        value-key="label"
        show-toolbar
        @cancel="cancel"
        @confirm="chooseType"
      ></Picker>
    </Popup>
    <Popup position="bottom" v-model="showBitPopup">
      <Picker
        :columns="bitList"
        value-key="name"
        show-toolbar
        @cancel="cancel"
        @confirm="chooseBit"
      ></Picker>
    </Popup>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import Clipboard from 'clipboard'
import headerBar from '@/components/header'
import { Field, Button, Popup, Picker } from 'vant'
import { getWalletInfo, addWithdraw } from '@/api/user'
export default {
  components: {
    headerBar,
    Field,
    Button,
    Popup,
    Picker
  },
  data() {
    return {
      form: {
        type: '',
        typeName: '',
        bit: '',
        bitName: '',
        link: '',
        number: '',
        password: '',
        address: '',
      },
      pageType: '',
      typeList: [
        {
          value: 1,
          label: this.$t('xu-ni-bi')
        },
        {
          value: 2,
          label: this.$t('yin-hang-ka')
        }
      ],
      showTypePopup: false,
      showBitPopup: false,
      headers: {},
      address: 'sdfasfkjsadfksjdflsdjfklf1123',
      bannerUrl: require('@/assets/imgs/recharge-bg.png'),
      bitList: [
       
        {
          icon: require('@/assets/imgs/icon-bit3.png'),
          name: 'USDT',
          key: 'usdt',
          linkList: [
            {
              key: 'trc20',
              name: 'TRC20'
            },
          ]
        }
      ],
      linkList: [],
      userMoney: ''
    }
  },
  computed: {},
  mounted() {
    this.linkList = this.bitList[0].linkList
    this.form.link = this.linkList[0].key
    this.form.bit = this.bitList[0].key
    this.form.bitName = this.bitList[0].name
    this.form.type = this.typeList[0].value
    this.form.typeName = this.typeList[0].label

    this.init()
    let name = this.$route.name
    this.pageType = name == 'wapUserWithdraw' ? 'user' : ''
  },
  methods: {
    init() {
      getWalletInfo().then((res) => {
        let data = res.data.Items[0]
        this.userMoney = data.Balance
      })
    },
    back() {
      this.$router.go(-1)
    },
    copyData() {
      let data = this.address
      const clipboard = new Clipboard('.copy-icon', { text: () => data })
      this.$toast(this.$t('fu-zhi-cheng-gong'))
    },
    changeType() {
      this.showTypePopup = true
    },
    changeBit() {
      this.showBitPopup = true
    },
    cancel() {
      this.showTypePopup = false
      this.showBitPopup = false
    },
    chooseType(e) {
      this.form.type = e.value
      this.form.typeName = e.label
      this.showTypePopup = false
    },
    chooseBit(e) {
      this.form.bit = e.key
      this.form.bitName = e.name
      this.linkList = e.linkList
      this.form.link = this.linkList[0].key
      this.showBitPopup = false
    },
    changeLink(data) {
      this.form.link = data.key
    },
    setAll() {
      this.form.number = this.userMoney
    },
    toDetail() {
      this.$router.push({
        name: 'wapWithdrawLog'
      })
    },
    submit() {
      if (!this.form.address) {
        this.$toast(this.$t('qing-shu-ru-di-zhi-0'))
        return
      }
      if (!this.form.number) {
        this.$toast(this.$t('qing-shu-ru-jine'))
        return
      }
      if (!this.form.password) {
        this.$toast(this.$t('qing-shu-ru-mi-ma'))
        return
      }
      addWithdraw({
        Price: parseFloat(this.form.number),
        PayPassword: this.form.password,
        Address: this.form.address,
        Currency: 'USDT-TRC20',
      }).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('ti-jiao-cheng-gong'))
          this.form.address = ''
          this.form.password = ''
          this.form.number = ''
        } else {
          this.$toast(res.msg)
        }
      })
    }
  }
}
</script>